<template lang='pug'>
Draggable(v-model='organizations' treeLine :disableDrag='true')
  template(#default='{ node, stat }')
    .d-flex.justify-space-between.align-center.py-2
      v-card.w-100(color='#edebfa' variant='elevated')
        v-card-text.py-0.ps-1.pe-0.d-flex.align-center
          v-row.me-0
            v-col.d-flex.align-center(cols='4')
              .d-flex
                OpenIcon(v-if='stat.children.length' :open='stat.open' class='mtl-mr mx-1' @click.native='stat.open = !stat.open')
                div.ms-2 {{ node.name }}
            v-col(cols='4')
              v-expansion-panels.m-1
                v-expansion-panel(title='所属メンバー')
                  v-expansion-panel-text
                    p(v-for='user in targetUsers(node.id)') {{ user.name }}
            v-col(cols='4')
              v-expansion-panels.m-1
                v-expansion-panel(title='所属ショーケース')
                  v-expansion-panel-text
                    p(v-for='showcase in targetShowcases(node.id)') {{ showcase.name }}
</template>

<script setup>
import { computed, ref } from 'vue'
import { BaseTree, Draggable, OpenIcon } from '@he-tree/vue'
import '@he-tree/vue/style/default.css'

const props = defineProps({
  organizations: {
    type: String,
    required: true
  },
  showcases: {
    type: String,
    required: true
  },
  users: {
    type: String,
    required: true
  }
})
const organizations = ref(JSON.parse(props.organizations))
const showcases = ref(JSON.parse(props.showcases))
const users = ref(JSON.parse(props.users))

const targetShowcases = computed(() => {
  return (id) => {
    return showcases.value.filter(showcase => showcase.organization_id === id)
  }
})

const targetUsers = computed(() => {
  return (id) => {
    return users.value.filter(user => user.organization_ids.includes(id))
  }
})
</script>
