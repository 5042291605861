<template>
<Editor
  v-model='privacyPolicyData.body'
  @keyUp='onChange'
  @mouseUp='onChange'
  @change='onChange'
  api-key='ioly5ghskf2uk5id8qhz3rlw9qn4d69n7ecj5tn9f7uqegg2'
  :init="{
    language: 'ja',
    height: 500,
    menubar: true,
    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl'
  }"
/>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  data() {
    return {
      privacyPolicyData: {}
    }
  },
  props: ['privacyPolicy'],
  components: { Editor },
  created() {
    const parsedPrivacyPolicy = JSON.parse(this.privacyPolicy)
    for (let key in parsedPrivacyPolicy) {
      this.privacyPolicyData[key] = parsedPrivacyPolicy[key]
    }
  },
  methods: {
    onChange() {
      document.getElementById('user_privacy_policy').value = JSON.stringify(this.privacyPolicyData)
    }
  }
}
</script>
