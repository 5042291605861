import vueRenderer from './vue_renderer'

import GuestbookForm from '../vue_components/form.vue'
import GuestbookFormBuilder from '../vue_components/guestbookFormEditor.vue'

import mpUrlParamsEditor from '../vue_components/mpUrlParamsEditor.vue'
import pageCustomizer from '../vue_components/pageCustomizer.vue'
import toggleConfig from '../vue_components/toggleConfig.vue'

import coverPageEditor from '../vue_components/coverPageEditor.vue'
import privacyPolicyEditor from '../vue_components/privacyPolicyEditor.vue'

import showcaseSearch from '../vue_components/showcaseSearch.vue'
import showcaseTagEditor from '../vue_components/showcaseTagEditor.vue'

import recordingView from '../vue_components/recordingView.vue'

import memberOrganizations from '../vue_components/memberOrganizations.vue'
import organizations from '../vue_components/organizations.vue'

vueRenderer('guestbook-form-app', GuestbookForm, 'showcase')
vueRenderer('guestbook-disabled-form-app', GuestbookForm)
vueRenderer('guestbook-editable-form-app', GuestbookForm)
vueRenderer('guestbook-form-builder-app', GuestbookFormBuilder)

vueRenderer('showcase-mp-url-params-editor', mpUrlParamsEditor)
vueRenderer('showcase-page-customizer', pageCustomizer)
vueRenderer('showcase-modules-config', toggleConfig)

vueRenderer('cover-page-container', coverPageEditor)
vueRenderer('privacy-policy-container', privacyPolicyEditor)

vueRenderer('showcase-search-container', showcaseSearch)
vueRenderer('showcase-tag-editor', showcaseTagEditor)

vueRenderer('recording-view-container', recordingView)

vueRenderer('member-organizations-container', memberOrganizations)
vueRenderer('organizations-container', organizations)
