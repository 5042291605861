<template lang='pug'>
div
  #guestbook-form-builder.mb-3
    label.form-label
      | フォーム
    FormBuilder(ref='formbuilder' :form='getFromFields()' :options='{}' v-on:change='onChange')
  .card.mt-3
    .card-body
      .h6.mb-0 背景画像設定
      .row
        .col-12
          .field.mt-3
            label.form-label 画像URL
            i.bi.bi-question-circle-fill.text-secondary.ms-1(
              data-bs-toggle='tooltip' data-bs-placement='bottom'
              title='URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な画像は5MB以下のpng、jpg、gifです。'
            )
            input#module-guestbook-bg-image-url.form-control(type='text' placeholder='http://xxx.com/background-image.png' v-model='guestbookData.background.image_url' @keyup='onChange')
            input.form-control.mt-2(type='file' id='showcase_guestbook_background' name='showcase[guestbook_background]')
            .guestbook_background_preview.mt-2(v-if='previewImageUrl')
              .form-label.mb-1
                | プレビュー
                .btn.btn-sm.btn-danger.ms-2(@click='onPurgeMedia' v-if='showPurgeButton')
                  i.bi.bi-trash-fill
              img.img-fluid(:src='previewImageUrl')
        .col-12.col-sm-4
          .field.mt-3
            label.form-label 画像サイズ
            i.bi.bi-question-circle-fill.text-secondary.ms-1(
              data-bs-toggle='tooltip' data-bs-placement='bottom' data-bs-html='true'
              title='background-sizeのスタイルを指定出来ます。詳しくは<a href="https://developer.mozilla.org/ja/docs/Web/CSS/background-size" target="_blank">こちら</a>から。'
            )
            input#module-guestbook-bg-size.form-control(type='text' placeholder='unset' v-model='guestbookData.background.size' @keyup='onChange')
        .col-12.col-sm-4
          .field.mt-3
            label.form-label 画像リピート
            i.bi.bi-question-circle-fill.text-secondary.ms-1(
              data-bs-toggle='tooltip' data-bs-placement='bottom' data-bs-html='true'
              title='background-repeatのスタイルを指定出来ます。詳しくは<a href="https://developer.mozilla.org/ja/docs/Web/CSS/background-repeat" target="_blank">こちら</a>から。'
            )
            input#module-guestbook-bg-repeat.form-control(type='text' placeholder='unset' v-model='guestbookData.background.repeat' @keyup='onChange')
        .col-12.col-sm-4
          .field.mt-3
            label.form-label 画像ポジション
            i.bi.bi-question-circle-fill.text-secondary.ms-1(
              data-bs-toggle='tooltip' data-bs-placement='bottom' data-bs-html='true'
              title='background-positionのスタイルを指定出来ます。詳しくは<a href="https://developer.mozilla.org/ja/docs/Web/CSS/background-position" target="_blank">こちら</a>から。'
            )
            input#module-guestbook-bg-position.form-control(type='text' placeholder='unset' v-model='guestbookData.background.position' @keyup='onChange')

      .h6.mt-4.mb-0 背景パターン設定
        i.bi.bi-question-circle-fill.text-secondary.ms-1(data-bs-toggle='tooltip' data-bs-placement='bottom' title='画像のURL指定がある場合はそちらが優先されます。')
      .row
        .col-12.col-sm-6
          .field.mt-3
            label.form-label カラー
            i.bi.bi-question-circle-fill.text-secondary.ms-1(data-bs-toggle='tooltip' data-bs-placement='bottom' title='パターンカラーを指定してください。「,」区切りでグラデーテョンを指定する事も可能です。')
            input#module-guestbook-bg-trianglify-colors.form-control(type='text' placeholder='単色「#cccccc」、グラデーション「#000000,#ffffff」' v-model='guestbookData.background.trianglify.color' @keyup='onChange')
        .col-12.col-sm-3
          .field.mt-3
            label.form-label セルサイズ
            i.bi.bi-question-circle-fill.text-secondary.ms-1(data-bs-toggle='tooltip' data-bs-placement='bottom' title='セルサイズを指定してください。')
            input#module-guestbook-bg-trianglify-size.form-control(type='text' placeholder='75' v-model='guestbookData.background.trianglify.size' @keyup='onChange')
        .col-12.col-sm-3
          .field.mt-3
            label.form-label セルサイズ変動値
            i.bi.bi-question-circle-fill.text-secondary.ms-1(data-bs-toggle='tooltip' data-bs-placement='bottom' title='セルサイズの変動値を指定してください。')
            input#module-guestbook-bg-trianglify-variance.form-control(type='text' placeholder='0.75' v-model='guestbookData.background.trianglify.variance' @keyup='onChange')
      .row
        .col-12
          .form-check.mt-3
            input#module-guestbook-for-cilbee.form-check-input(type='checkbox' v-model='guestbookData.for_cilbee' @change='onChange')
            label.form-check-label(for='module-guestbook-for-cilbee') ゲストブックをcilbeeのアンケートとして利用する

</template>

<script>
import axios from 'axios'
import { FormBuilder } from '@formio/vue'

export default {
  name: 'app',
  data() {
    return {
      guestbookData: {},
      previewImageUrl: this.guestbookBackgroundUrl,
      showPurgeButton: this.purgeImageUrl
    }
  },
  props: ['moduleGuestbook', 'guestbookBackgroundUrl', 'purgeImageUrl'],
  components: { FormBuilder },
  created() {
    const parsedModuleGuestbook = JSON.parse(this.moduleGuestbook)
    for (let key in parsedModuleGuestbook) {
      this.guestbookData[key] = parsedModuleGuestbook[key]
    }
  },
  methods: {
    onChange() {
      document.getElementById('showcase_module_guestbook_data').value = JSON.stringify({
        form_fields: this.$refs.formbuilder.builder.instance.schema,
        background: this.guestbookData.background,
        for_cilbee: this.guestbookData.for_cilbee
      })
    },
    getFromFields() {
      return this.guestbookData.form_fields
    },
    onPurgeMedia() {
      const result = confirm('画像ファイルを削除しますがよろしいですか？')
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
      if (result) {
        axios.delete(this.purgeImageUrl)
        this.previewImageUrl = this.guestbookData.background.image_url || null
        this.showPurgeButton = false
      }
    }
  }
}
</script>
