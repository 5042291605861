<template lang='pug'>
div
  template(v-for='(name, i) in moduleNames')
    .form-check.form-switch(v-if='moduleAuthorityData[name]')
      input.form-check-input(:id='`module-toggle-${name}`' type='checkbox' v-model='modulesData[name]' @change='onChange')
      label.form-check-label(:for='`module-toggle-${name}`') {{ labels[i] }}
    .text-muted(v-if='moduleAuthorityData[name] && name === "cilbee"') ※cilbeeを有効にする場合はドールハウスを有効にして下さい。

  div(v-if='!Object.keys(moduleAuthorityData).some(key => moduleAuthorityData[key])')
    p.mb-0 利用可能な機能はありません。
    //- a(href='')
    //-   | 詳しくはこちらから
</template>

<script>
export default {
  data() {
    return {
      modulesData: {},
      moduleAuthorityData: {},
      moduleNames: []
    }
  },
  created() {
    const data = document.getElementById('showcase-modules-config').dataset

    const parsedModules = JSON.parse(data.modules)
    for (let key in parsedModules) {
      this.modulesData[key] = parsedModules[key]
    }
    this.moduleAuthorityData = JSON.parse(data.moduleAuthority)
    this.moduleNames = JSON.parse(data.moduleNames)
    this.labels = JSON.parse(data.moduleNameLabels)
  },
  methods: {
    onChange() {
      document.getElementById('showcase_modules').value = JSON.stringify(this.modulesData)
    }
  }
}
</script>
