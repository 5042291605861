<template lang='pug'>
div
  div(v-if='currentUserTags.length')
    span.badge.rounded-pill.bg-light.mt-2.mx-1(v-for='tag in currentUserTags')
      label.form-check-label.d-flex.align-items-center.m-1(:for='`tag-${tag.id}`')
        input.form-check-input.me-1.my-0(:id='`tag-${tag.id}`' :name='`tag-${tag.id}`' type='checkbox' :data-tag-id='tag.id' :checked='checked(tag.id)' @change='onChange')
        span.text-dark {{ `#${tag.name}` }}

  div(v-else)
    p ハッシュタグが作成されていません。

  .mt-3
    .input-group
      span.input-group-text #
      input.form-control(v-model='newTagName' type='text' placeholder='ハッシュタグ名')
      button.btn.btn-primary(@click='createTag' :disabled='newTagName.length === 0')
        i.bi.bi-plus-circle.me-1
        | ハッシュタグ作成
    span#alert-text.ms-2(style='color:red' v-if='errorText') {{ errorText }}

</template>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        apiUrl: null,
        currentUserTags: [],
        errorText: null,
        newTagName: '',
        showcaseId: null,
        showcaseTags: []
      }
    },
    computed: {
      checked() {
        return tagId => {
          return this.showcaseTags.find(showcaseTag => showcaseTag.tag_id === tagId)._destroy === 0
        }
      }
    },
    created() {
      this.apiUrl = document.getElementById('showcase-tag-editor').dataset.createTagUrl
      this.currentUserTags = JSON.parse(document.getElementById('showcase-tag-editor').dataset.userTags)
      this.showcaseId = document.querySelector('#showcase.editor') ? Number(document.querySelector('#showcase.editor').dataset.showcaseId) : null
      const createdShowcaseTags = JSON.parse(document.getElementById('showcase_showcase_tags_attributes').value)

      this.showcaseTags = this.currentUserTags.map(tag => {
        const created = createdShowcaseTags.find(createdShowcaseTag => createdShowcaseTag.tag_id === tag.id)
        return { id: created ? created.id : null, showcase_id: this.showcaseId, tag_id: tag.id, _destroy: created ? 0 : 1 }
      })
    },
    methods: {
      async createTag(e) {
        e.preventDefault()

        const res = await axios.post(`${this.apiUrl}.json`, {
          tag: { name: this.newTagName }
        }).catch(err => {
          this.errorText = err.response.data.error || err
        })

        if (res && res.data) {
          const newTag = JSON.parse(res.data.tag)
          this.currentUserTags.push(newTag)
          this.showcaseTags.push({ id: null, showcase_id: this.showcaseId, tag_id: newTag.id, _destroy: 1 })

          this.errorText = null
          this.newTagName = ''
        }
      },
      onChange(e) {
        this.showcaseTags.forEach(showcaseTag => {
          if (showcaseTag.tag_id === Number(e.target.dataset.tagId)) {
            showcaseTag._destroy = e.target.checked ? 0 : 1
          }
        })
      },
    },
    mounted() {
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    },
    watch: {
      showcaseTags:{
        handler(newData, _oldData) {
          document.getElementById('showcase_showcase_tags_attributes').value = JSON.stringify(newData)
        },
        deep: true
      }
    }
  }
</script>
