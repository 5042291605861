<template lang='pug'>
div(v-if='hasAdmin')
  v-row.mb-2
    v-col(cols='3')
      v-text-field(v-model='name' label='組織名' hide-details)
    v-col(cols='7')
      v-text-field(v-model='description' label='説明文' hide-details)
    v-col(cols='2')
      v-btn.mt-2(block prepend-icon='mdi-plus' @click='createOrganization' :disabled='!name') 新規作成
  span(v-if='!dialog && errorMessage' style='color: red') {{ errorMessage }}

Draggable(v-model='organizations' treeLine @change='updateRelationships')
  template(#default='{ node, stat }')
    .py-2
      v-card.w-100(color='#edebfa' variant='elevated')
        v-card-text.py-0.ps-1.pe-0.d-flex.align-center
          OpenIcon(v-if='stat.children.length' :open='stat.open' class='mtl-mr mx-1' @click.native='stat.open = !stat.open')
          .d-flex
            v-icon.me-1(icon='mdi-drag')
            | {{ node.name }}
          .d-flex.ml-auto
            v-divider.my-2(vertical color='primary')
            v-btn(v-if='node.admin' variant='text' size='small' color='secondary' icon='mdi-square-edit-outline' @click='showDialog(node.id)')

v-dialog(v-model='dialog', width='90%' max-height='90%' scrollable)
  v-card(v-if='selectedOrganizationData' title='組織編集')
    v-divider
    v-card-text
      v-text-field(v-model='selectedOrganizationData.name' label='組織名' hide-details)
      v-text-field.mt-3(v-model='selectedOrganizationData.description' label='説明文' hide-details)
      span(v-if='dialog && errorMessage' style='color: red') {{ errorMessage }}

      v-tabs.mt-5(v-model='tab' fixed-tabs)
        v-tab(value='tab-members' text='メンバー')
        v-tab(value='tab-showcases' text='ショーケース')

      v-tabs-window(v-model='tab')
        v-tabs-window-item(value='tab-members')
          v-card
            v-card-text
              h6 メンバー一覧

              v-row.mt-3.border-bottom
                v-col(cols='2') ID
                v-col(cols='5') 名前
                v-col(cols='5') 権限
              v-row.border-bottom(v-for='user in selectedOrganizationData.users')
                v-col(cols='2') {{ user.id }}
                v-col(cols='5') {{ getUserName(user.id) }}
                v-col(cols='5') {{ getRoleName(user.role) }}

              v-expansion-panels.mt-5
                v-expansion-panel
                  v-expansion-panel-title メンバー編集
                  v-expansion-panel-text
                    v-data-table(v-model='selectedUserIds' :items='users' item-value='id' density='compact' show-select hide-default-header @update:modelValue='changeAffiliation')
                      template(v-slot:item.role="{ item }")
                        v-select.my-2(v-model='item.role' :items='abilityList' density='compact' label='権限' hide-details @update:modelValue='changeAbility(item)')

        v-tabs-window-item(value='tab-showcases')
          v-card
            v-card-text
              h6 ショーケース一覧

              v-row.mt-3.border-bottom
                v-col(cols='2') ID
                v-col(cols='5') SID
                v-col(cols='5') 名前
              v-row.border-bottom(v-for='showcase in selectedOrganizationData.showcases')
                v-col(cols='2') {{ showcase.id }}
                v-col(cols='5') {{ showcase.sid }}
                v-col(cols='5') {{ showcase.name }}

              v-expansion-panels.mt-5
                v-expansion-panel
                  v-expansion-panel-title ショーケース編集
                  v-expansion-panel-text
                    v-data-table(v-model='selectedShowcaseIds' :items='showcases' item-value='id' density='compact' show-select hide-default-header)

    template(v-slot:actions)
      v-btn(color='red' prepend-icon='mdi-trash-can' text='削除', @click='deleteOrganization')
      v-btn.ms-auto(prepend-icon='mdi-check' text='OK', @click='closeDialog')
</template>

<script setup>
import axios from 'axios'
import { ref } from 'vue'
import { BaseTree, Draggable, OpenIcon } from '@he-tree/vue'
import '@he-tree/vue/style/default.css'

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}

const props = defineProps({
  hasAdmin: {
    type: Boolean
  },
  organizationBaseUrl: {
    type: String,
    required: true
  },
  organizations: {
    type: String,
    required: true
  },
  showcases: {
    type: String,
    required: true
  },
  users: {
    type: String,
    required: true
  }
})
const hasAdmin = props.hasAdmin
const organizationBaseUrl = props.organizationBaseUrl
const organizations = ref(JSON.parse(props.organizations))
const showcases = ref(JSON.parse(props.showcases))
const users = ref(JSON.parse(props.users))
users.value.forEach(user => user.role = 'none')

const abilityList = [
  { title: '未所属', value: 'none'},
  { title: '管理者', value: 'org_admin' },
  { title: '編集者', value: 'org_editor' },
  { title: '閲覧者', value: 'org_member' }
]
const description = ref(null)
const dialog = ref(false)
const errorMessage = ref(null)
const name = ref(null)
const selectedOrganizationData = ref(null)
const selectedShowcaseIds = ref([])
const selectedUserIds = ref([])
const tab = ref('tab-members')

const changeAbility = (item) => {
  if (item.role === 'none') {
    selectedUserIds.value = selectedUserIds.value.filter(id => id !== item.id)
  } else if (!selectedUserIds.value.includes(item.id)) {
    selectedUserIds.value.push(item.id)
  }
}

const changeAffiliation = (item) => {
  users.value.forEach(user => {
    const data = selectedUserIds.value.find(id => id === user.id)
    if (data) {
      if (user.role === 'none') {
        user.role = 'org_member'
      }
    } else {
      user.role = 'none'
    }
  })
}

const closeDialog = async () => {
  try {
    const newData = selectedOrganizationData.value
    newData.showcase_ids = selectedShowcaseIds.value
    newData.user_ids = selectedUserIds.value
    const user_roles = selectedUserIds.value.map(id => {
      const data = users.value.find(user => user.id === id)
      return { id: id, role: data.role }
    })

    const res = await axios.patch(`${organizationBaseUrl}/${selectedOrganizationData.value.id}`, {
      organization: { ...newData },
      user_roles: user_roles
    })
    organizations.value = res.data.data
    selectedOrganizationData.value = null
    selectedShowcaseIds.value = []
    selectedUserIds.value = []
    errorMessage.value = null
    dialog.value = false
    showToast(res.data.message)
  } catch (error) {
    errorMessage.value = error?.response?.data?.join(',')
  }
}

const createOrganization = async () => {
  try {
    const res = await axios.post(organizationBaseUrl, {
      organization: { name: name.value, description: description.value }
    })
    organizations.value = res.data.data
    errorMessage.value = null
    showToast(res.data.message)
  } catch (error) {
    errorMessage.value = error?.response?.data?.join(',')
  }
}

const deleteOrganization = async () => {
  const result = confirm('削除しますか？　子組織がある場合、子組織は自動的に更に上の組織の子組織になるか、該当組織がない場合は空になります。')
  if (result) {
    try {
      const res = await axios.delete(`${organizationBaseUrl}/${selectedOrganizationData.value.id}`)
      organizations.value = res.data.data
      selectedOrganizationData.value = null
      errorMessage.value = null
      dialog.value = false
      showToast(res.data.message)
    } catch (error) {
      errorMessage.value = error?.response?.data?.join(',')
    }
  }
}

const getParams = (nodes, parentId = null, result = []) => {
  nodes.forEach((node, index) => {
    result.push({ id: node.id, parent_id: parentId, order: index })
    if (node.children?.length > 0) {
      getParams(node.children, node.id, result)
    }
  })
  return result
}

const getRoleName = (role) => {
  if (role === 'org_admin') {
    return '管理者'
  }
  if (role === 'org_editor') {
    return '編集者'
  }
  if (role === 'org_member') {
    return '閲覧者'
  }
  return '未所属' // NOTE: 出てこないはず
}

const getUserName = (id) => {
  return users.value.find(user => user.id === id).name
}

const showDialog = async (id) => {
  try {
    const res = await axios.get(`${organizationBaseUrl}/${id}`)
    selectedOrganizationData.value = res.data
    res.data.showcases.forEach(showcase =>  selectedShowcaseIds.value.push(showcase.id))
    res.data.users.forEach(user =>  selectedUserIds.value.push(user.id))
    users.value.forEach(user => {
      const roleData = res.data.users.find(data => user.id === data.id)
      user.role = roleData?.role || 'none'
    })
    dialog.value = true
  } catch (error) {
    errorMessage.value = error?.response?.data?.join(',')
  }
}

const showToast = (message) => {
  const toastElList = [].slice.call(document.querySelectorAll('.toast'))
  if (toastElList.length === 0) {
    // NOTE: Toastが自動作成されないので、ここで作成している
    const toastDiv = document.createElement('div')
    toastDiv.setAttribute('aria-atomic', 'true')
    toastDiv.setAttribute('aria-live', 'assertive')
    toastDiv.setAttribute('role', 'alert')
    toastDiv.classList.add('toast', 'mb-3', 'align-items-center', 'text-white', 'border-0', 'bg-success', 'fade', 'hide')
    toastDiv.innerHTML =
      `<div class="d-flex"><div class="toast-body">${message}</div><button aria-label="Close" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" type="button"></button></div></div>`
    document.getElementById('flash').appendChild(toastDiv)
    new Toast(toastDiv).show()
  } else {
    toastElList[0].querySelector('.toast-body').innerHTML = message
    new Toast(toastElList[0]).show()
  }
}

const updateRelationships = async () => {
  const newRelationships = getParams(organizations.value)
  try {
    const res = await axios.patch(`${organizationBaseUrl}/update_relationships`, {
      new_relationships: newRelationships
    })
    organizations.value = res.data
    errorMessage.value = null
  } catch (error) {
    errorMessage.value = error?.response?.data?.join(',')
  }
}
</script>
